<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				likeThis:{}
			}
		},
		computed:{
			mySlug(){
				return this.$route.params.slug
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_our_blog,
					link: { name: 'Blog' }
				},{
					name: (this.data.title||'-'),
					link: { name: 'DetailBlog' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getApi()
		},
		methods: {
			getApi() {
				Gen.apirest("/detail-blog/"+this.mySlug,{},(err,resp)=>{
					this.$root.topProgress.done()
					if(err) console.log(err)
					if(resp.code != 200) return this.$router.push({name:'Page404'})
					this.data = resp.data
					this.likeThis = resp.likeThis
				})
			},
			shareFB() {
       			window.open("https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(this.currentUrl), 'fbShareWindow', 'top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
			},
			shareTW(){
				window.open("https://twitter.com/intent/tweet?text="+encodeURI(this.data.title+" -")+"&url="+encodeURI(this.currentUrl),"","modal=yes")
			},
		},
		watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="row justify-content-center">
					<article class="col-md-10 col-lg-8">
						<div class="article-heading">
							<div class="ah-title">
								<h1>{{data.title}}</h1>
								<span class="ah-date">{{(data.publish_date||"").dates("format")}}</span>
								<span class="ah-author">{{data.author}}</span>
							</div>
							<div class="ah-share">
								<div class="row align-items-center">
									<div class="col-2 col-md-1">
										<span>{{web.lbl_share}}</span>

									</div>
									<div class="col-6">
										<div class="social-icons">
											<a href="javascript:;" @click="shareFB"
												class="social-icon si-dark  si-small si-borderless si-rounded si-facebook">
												<i class="icon-facebook"></i>
												<i class="icon-facebook"></i>
											</a>
											<a href="javascript:;" @click="shareTW"
												class="social-icon si-small si-dark si-borderless  si-rounded si-twitter">
												<i class="icon-twitter"></i>
												<i class="icon-twitter"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
							<!-- <?php if(!isset($_GET['videoThumb'])){ ?> -->
							<img :src="uploader(data.image)" alt="Blog Thumbnail" class="ah-thumbnail">
							<!-- <?php }else{ ?>
							<div class="ah-thumbnail video-thumbnail">
								<a href="https://www.youtube.com/watch?v=87A_SZ0Y6hA" data-lightbox="iframe">
									<img src="images/redwine-bg.jpg" alt="Video Thumbnail">
									<div class="play-icon">
										<i class="icon-play"></i>
									</div>
								</a>
							</div>
							<?php } ?> -->
						</div>
						<div class="article-content" v-html="data.description"></div>
						<div class="article-footer" v-if="data && likeThis.length">
							<h4>{{web.lbl_more_link_this}}</h4>
							<div class="row justify-content-center">
								<div class="col-md-4 col-6" v-for="(v,k) in likeThis" :key="k">
									<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}" class="related-article">
										<div class="ra-img">
											<img :src="uploader(v.image)" alt="Related Article Thumbnail">
										</div>
										<div class="ra-desc">
											<h5>{{v.title}}</h5>
											<span>{{(v.publish_date||"").dates("format")}}</span>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</article>
				</div>
			</div>
		</section>
	</div>
</template>